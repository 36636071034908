<template>

    <div class='list_content'>
        <h2 style="text-align: center;">添加表单</h2>
        <el-form ref="form" :model="form" label-width="80px">

            <el-form-item label="*姓名" style="margin-left: 0px;">
                <el-input v-model="form.name"></el-input>
            </el-form-item>

            <el-form-item label="*电话" width="120px">
                <el-input @input="fangdouCheckPhone" v-model="form.telphone"></el-input>
                <span id="phoneCheckOk" style="font-size:12px;color:green ;display:none;">手机号验证通过</span>
                <span id="phoneCheckNo" style="font-size:12px;color:red;display:none;">手机号不合法</span>
            </el-form-item>

            <el-form-item label="*分配给谁">
                <el-select @change="selectUserChange" filterable v-model="form.user_id" placeholder="请选择">
                    <el-option v-for="item in lv3User" :key="item.user_id" :label="item.user_name"
                        :value="item.user_id"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="*来源平台">
                <el-select v-model="form.app_name" placeholder="请选择">
                    <el-option label="字节-抖音" value="字节-抖音"></el-option>
                    <el-option label="字节-今日头条" value="字节-今日头条"></el-option>
                    <el-option label="微信" value="微信"></el-option>
                    <el-option label="快手" value="快手"></el-option>
                    <el-option label="百度" value="百度"></el-option>
                    <el-option label="小红书" value="小红书"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="*省份" width="120px">
                <el-input v-model="form.province_name"></el-input>
            </el-form-item>

            <el-form-item label="*城市" width="120px">
                <el-input v-model="form.city_name"></el-input>
            </el-form-item>

            <el-form-item label="*科室" width="120px">
                <el-input v-model="form.ks_type"></el-input>
            </el-form-item>

            <el-form-item label="*创建时间" width="120px">
                <el-input @input="fangDouCheckTime()" v-model="form.create_time"></el-input>
                <span id="timeCheckOk" style="font-size:12px;color:green;display:none;">日期格式验证通过</span>
                <span id="timeCheckNo" style="font-size:12px;color:red;display:none;">日期格式不合法</span>
            </el-form-item>

            <el-button type="primary" id="userSearch" @click="inputCurrent">当前时间</el-button>
            <el-button type="primary" @click="onSubmit">确认</el-button>
            <el-button @click="goback">取消</el-button>
        </el-form>
    </div>

</template>

<script>
import LeftMenu from '@/components/left.vue'
import HeaderTop from '@/components/top.vue'
import Footer from '@/components/footer.vue'

export default {
    name: 'FormAddOneView',
    // 注册组件
    components: {
        LeftMenu,
        HeaderTop,
        Footer
    },
    props: {},
    // data() 为变量赋值等
    data() {
        return {
            cache_user: {},
            form: {
                name: "",
                telphone: "",
                ks_type: "",
                province_name: "",
                city_name: "",
                create_time: "",
                app_name: "",
                user_id: "",
            },
            lv3User: [],
            formatPhoneCHeck: false,
            formatTimeCHeck: false,
            checkPhoneTimer: null,
            checkCreateTimeTimer: null,
        }
    },

    created() {
        let that = this
        // 从缓存获取user
        let userInfo = JSON.parse(sessionStorage.getItem('user'))
        if (userInfo == "" || !userInfo) {
            that.$router.push({
                path: '/public/login',
            })
            return
        }
        that.cache_user = userInfo
        // 获取 lv3 可分配的用户
        that.myAxios.post(that.myBaseurl + '/user/lv3', "")
            .then(function (res) {
                if (res.data.Code === '200') {
                    console.log(res.data.Data);
                    that.lv3User = res.data.Data
                } else {
                    alert("获取lv3User用户信息失败!")
                }
            })
    },

    methods: {
        selectUserChange(userId) {
            console.log(`select userId: ${userId}`);
            if (userId) {
                for (const userItem of this.lv3User) {
                    if (userItem.user_id == userId) {
                        console.log(userItem);
                        this.form.ks_type = userItem.ks_type
                        this.form.province_name = userItem.province_name
                        this.form.city_name = userItem.city_name
                        this.form.app_name = userItem.app_name
                    }
                }
            }
        },
        inputCurrent() {
            this.form.create_time = this.$dateFormat(new Date(), "YYYY-MM-DD hh:mm:ss")
            this.checkCreateTime(this.form.create_time)
        },
        checkPhone(phone) {
            console.log("phone: ", phone);
            let re = /^1([3|4|5|6|7|8|9])\d{9}$/;
            if (re.test(phone)) {
                document.getElementById("phoneCheckOk").style.display = "block";
                document.getElementById("phoneCheckNo").style.display = "none";
                this.formatPhoneCHeck = true;
            } else {
                document.getElementById("phoneCheckNo").style.display = "block";
                document.getElementById("phoneCheckOk").style.display = "none";
                this.formatPhoneCHeck = false;
            }
        },
        fangdouCheckPhone() {
            if (this.checkPhoneTimer) {
                clearTimeout(this.checkPhoneTimer);
            }
            this.checkPhoneTimer = setTimeout(() => {
                this.checkPhone(this.form.telphone)
            }, 500);
        },
        fangDouCheckTime() {
            if (this.checkCreateTimeTimer) {
                clearTimeout(this.checkCreateTimeTimer);
            }
            this.checkCreateTimeTimer = setTimeout(() => {
                this.checkCreateTime(this.form.create_time)
            }, 500);
        },

        checkCreateTime(createTime) {
            console.log("createTime: ", createTime);
            console.log("createTime.substring(0, 2): ", createTime.substring(0, 2));
            if (createTime.substring(0, 2) != "20") {
                // alert("日期格式不合法 !");
                document.getElementById("timeCheckNo").style.display = "block";
                document.getElementById("timeCheckOk").style.display = "none";
                this.formatTimeCHeck = false;
                return
            };
            let timeArr = createTime.split(" ");
            console.log("timeArr: ", timeArr);
            if (timeArr.length != 2) {
                // alert("日期格式不合法 !");
                document.getElementById("timeCheckNo").style.display = "block";
                document.getElementById("timeCheckOk").style.display = "none";
                this.formatTimeCHeck = false;
                return
            };
            let nyr = timeArr[0].split("-");
            if (nyr.length != 3) {
                // alert("日期格式不合法 !");
                document.getElementById("timeCheckNo").style.display = "block";
                document.getElementById("timeCheckOk").style.display = "none";
                this.formatTimeCHeck = false;
                return
            }

            console.log("nyr[0]: ", nyr[0], nyr[1], nyr[2]);
            if (nyr[0].length != 4 || nyr[1].length != 2 || nyr[2].length != 2) {
                // alert("日期格式不合法 !");
                document.getElementById("timeCheckNo").style.display = "block";
                document.getElementById("timeCheckOk").style.display = "none";
                this.formatTimeCHeck = false;
                return
            }
            let sfm = timeArr[1].split(":");
            if (sfm.length != 3) {
                // alert("日期格式不合法 !");
                document.getElementById("timeCheckNo").style.display = "block";
                document.getElementById("timeCheckOk").style.display = "none";
                this.formatTimeCHeck = false;
                return
            }
            console.log("sfm[0]: ", sfm[0], sfm[1], sfm[2]);
            if (sfm[0].length != 2 || sfm[1].length != 2 || sfm[2].length != 2) {
                // alert("日期格式不合法 !");
                document.getElementById("timeCheckNo").style.display = "block";
                document.getElementById("timeCheckOk").style.display = "none";
                this.formatTimeCHeck = false;
                return
            }
            // alert("日期格式正确 !")
            document.getElementById("timeCheckOk").style.display = "block";
            document.getElementById("timeCheckNo").style.display = "none";
            this.formatTimeCHeck = true;

        },
        onSubmit() {
            const that = this

            console.log('开始参数校验: ', that.form);

            if (that.form.name == "") {
                alert("姓名不能为空 !")
                return
            }
            if (that.form.ks_type == "") {
                alert("科室不能为空 !")
                return
            }

            if (that.form.province_name == "") {
                alert("省份不能为空 !")
                return
            }

            if (that.form.city_name == "") {
                alert("城市不能为空 !")
                return
            }

            if (that.form.app_name == "") {
                alert("来源不能为空 !")
                return
            }

            if (that.form.user_id == "") {
                alert("分配单位不能为空 !")
                return
            }

            if (!this.formatTimeCHeck || !this.formatPhoneCHeck) {
                alert("手机号或者日期验证不通过 !")
                return
            }

            if (that.form.province_name.indexOf("省") == -1) {
                that.form.province_name.replace("省", "")
            }
            if (that.form.city_name.indexOf("市") == -1) {
                that.form.city_name.replace("市", "")
            }

            that.form.user_id = Number(that.form.user_id)

            console.log('开始添加表单: ', that.form);

            this.myAxios.post(this.myBaseurl + '/form/phone/count', this.form).then(res => {
                if (res.data.Code === '200') {
                    if (res.data.Data > 0 && confirm(`该手机号已存在 ${res.data.Data} 条,是否继续添加?`) == true) {
                        return this.myAxios.post(this.myBaseurl + '/form/add', this.form)
                    }
                    if (res.data.Data == 0) {
                        return this.myAxios.post(this.myBaseurl + '/form/add', this.form)
                    }
                } else {
                    res.data.Msg = "errerrerrerrerrerrerrerrerrerrerrerrerrerrerr" + res.data.Msg
                    return Promise.reject(res)
                }
            }).then(res => {
                if (res.data.Code == "200") {
                    alert(res.data.Msg)
                } else {
                    res.data.Msg = "errerrerrerrerrerrerrerrerrerrerrerrerrerrerr" + res.data.Msg
                    return Promise.reject(res)
                }
            }).catch(res => alert(res.data.Msg))
        },

        goback() {
            history.go(-1)
        }
    }
}

</script>

<style scoped="scoped">
/*大框体样式*/
.el-main {
    height: calc(100vh - 120px);
}

.list_content {
    max-width: 800px;
}

.el-form-item__label {
    max-width: 200px !important;
}

#userSearch {
    background-color: rgb(51, 207, 121);
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: 12px;
    height: 42px;
    line-height: 42px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 85px;
    margin-left: 80px;
    margin-right: 20px;
    color: white;
}
</style>
